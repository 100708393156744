@mixin theme($theme) {
  .mat-small-icon-button {
    &.mat-mdc-icon-button {
      width: 34px;
      height: 34px;
      padding: 4px;
      border-radius: 4px;

      .mat-mdc-button-persistent-ripple {
        border-radius: 4px;
      }
    }

    &[color='primary'] {
      color: var(--app-primary-contrast-500);
      background-color: var(--app-primary-500);
    }

    &[color='warning'] {
      color: var(--app-primary-contrast-200);
      background-color: var(--app-warning-200);
    }
  }
}
