/* stylelint-disable selector-class-pattern */
@use '@angular/material' as mat;
@use '@material/textfield';
@use 'sass:map';

@mixin color($theme) {
  // Get the color config from the theme.
  // we use map.get instead of mat.get-color-config
  // because the color was set using map-merge
  //   $color-config: mat.get-color-config($theme);
  $color-config: map.get($theme, 'custom-color');

  // Get the primary color palette from the color-config.
  $secondary-palette: map.get($color-config, 'secondary');
  $primary-palette: map.get(mat.get-color-config($theme), 'primary');
}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: mat.get-typography-config($theme);
}

/* stylelint-disable scss/at-if-no-null */
@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $typography-config: mat.get-typography-config($theme);

  @include global;

  @if $color-config != null {
    @include color($theme);
  }

  @if $typography-config != null {
    @include typography($theme);
  }
}

@mixin global() {
  survey-container {
    .mdc-text-field--filled {
      @include textfield.fill-color(white);
      @include textfield.focused-outline-color(var(--main-color));

      .mdc-line-ripple {
        &::before,
        &::after {
          border-color: var(--main-color);
        }
      }
    }

    .mat-mdc-form-field-focus-overlay {
      background: none;
    }
  }

  app-chats,
  app-patient {
    .mdc-text-field--focused {
      .mat-mdc-form-field-focus-overlay {
        background-color: var(--app-white);
      }
    }

    .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
      opacity: 0;
    }

    .mdc-text-field--filled {
      background-color: var(--app-white) !important;
    }
  }

  mat-form-field {
    width: 100% !important;
  }
}
