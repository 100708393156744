/* stylelint-disable */
@use '@angular/material' as mat;
@use '../../modules/table.scss' as custom-table;
@use '../../modules/mat-button.scss' as custom-mat-button;
@use '../../modules/mat-tab-group.scss' as custom-tabs-group;
@use '../../modules/mtx-datetimepicker.scss' as custom-datetimepicker;
@use '../../modules/mat-dialog.scss' as custom-mat-dialog;
@use '../../modules/mat-form-field.scss' as custom-mat-form-field;
@use '../../modules/mat-snackbar.scss' as custom-snackbar;
@use '../../modules/mat-select.scss' as custom-mat-select;
@use '../../modules/mat-radio.scss' as custom-mat-radio;
@use '../../modules/ngx-slider.scss' as custom-ngx-slider;
@use '../color-palette.scss' as palette;
@use '../typography.scss' as custom-typography;
@use '../custom-typography-hierarchy.scss' as custom-typography-hierarchy;
@use '@ng-matero/extensions' as mtx;

@include mat.core();

$app-primary: palette.$remedee-purple;
$app-secondary: palette.$grey;

// TODO write a mixin that generate the code below

$my-primary: mat.define-palette($app-primary, 500);
$my-secondary: mat.define-palette($app-secondary, 500);
$my-accent: mat.define-palette($app-primary, 600);

// The "warn" palette is optional and is used by material for warn.
$my-warn: mat.define-palette(mat.$red-palette);
$initial-typography-config: mat.define-typography-config(
  $body-1: mat.define-typography-level(16px, 22px, 400),
  $body-2: mat.define-typography-level(16px, 22px, 500),
  $font-family: 'Open Sans',
);

@mixin typography($theme, $typography-config, $level, $selector) {
  $typography-config: mat.get-typography-config($theme);

  #{$selector} {
    @include mat.typography-level($typography-config, $level);
  }
}

$custom-typography-config: custom-typography.typography-all(
  $initial-typography-config
);
@include custom-typography.create-custom-typography-classes(
  $custom-typography-config
);

/* stylelint-disable scss/no-global-function-names */
$my-theme: map-merge(
  mat.define-light-theme(
    (
      color: (
        primary: $my-primary,
        secondary: $my-secondary,
        accent: $my-accent,
        warn: $my-warn,
      ),
      typography: $custom-typography-config,
      density: 0,
    )
  ),
  (
    'custom-color': (
      'secondary': $my-secondary,
    ),
  )
);

/* stylelint-enable scss/no-global-function-names */

@include mat.all-component-themes($my-theme);
@include mat.typography-hierarchy($my-theme);
@include mtx.all-component-themes($my-theme);

@include custom-typography-hierarchy.global($my-theme);
@include custom-table.theme($my-theme);
@include custom-mat-button.theme($my-theme);
@include custom-tabs-group.theme($my-theme);
@include custom-datetimepicker.theme($my-theme);
@include custom-mat-dialog.theme($my-theme);
@include custom-mat-form-field.theme($my-theme);
@include custom-snackbar.theme($my-theme);
@include custom-mat-select.theme($my-theme);
@include custom-mat-radio.theme($my-theme);
@include custom-ngx-slider.theme($my-theme);
