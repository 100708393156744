/* stylelint-disable selector-class-pattern */
@use '@angular/material' as mat;
@use '@material/textfield';
@use 'sass:map';

@mixin color($theme) {
  // Get the color config from the theme.
  // we use map.get instead of mat.get-color-config
  // because the color was set using map-merge
  //   $color-config: mat.get-color-config($theme);
  $color-config: map.get($theme, 'custom-color');

  // Get the primary color palette from the color-config.
  $secondary-palette: map.get($color-config, 'secondary');
  $primary-palette: map.get(mat.get-color-config($theme), 'primary');
}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: mat.get-typography-config($theme);
}

@mixin global() {
  admin-coach-vacation-item {
    mat-select {
      .mat-mdc-select-trigger {
        padding: 0 1em;
      }

      .mat-mdc-select-value,
      .mat-mdc-select-arrow {
        color: var(--app-white);
      }

      &.mat-mdc-select-disabled {
        .mat-mdc-select-value {
          color: white;
        }

        .mat-mdc-select-arrow {
          visibility: hidden;
        }
      }
    }
  }
}

/* stylelint-disable scss/at-if-no-null */
@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $typography-config: mat.get-typography-config($theme);

  @include global;

  @if $color-config != null {
    @include color($theme);
  }

  @if $typography-config != null {
    @include typography($theme);
  }
}
