@mixin theme($theme) {
  .ngx-slider {
    .ngx-slider-bar {
      height: 35px !important;
      border-radius: 50px !important;
    }

    .ngx-slider-bar-wrapper {
      margin-top: -26px !important;
    }

    .ngx-slider-bubble {
      visibility: hidden !important;
    }

    /* stylelint-disable-next-line no-duplicate-selectors */
    .ngx-slider-bar {
      height: 20px !important;
    }

    .ngx-slider-selection {
      width: calc(100% + 30px) !important;
      height: 20px !important;
      margin-left: -15px !important;

      // border-radius: 50px 0 0 50px !important;
      border-radius: 50px !important;
    }

    .ngx-slider-pointer {
      margin-top: -2px !important;
      visibility: hidden !important;
    }

    &.score-green {
      .ngx-slider-selection {
        background: #3dd99a !important;
      }
    }

    &.score-yellow {
      .ngx-slider-selection {
        background: #fad070 !important;
      }
    }

    &.score-orange {
      .ngx-slider-selection {
        background: #ffb35a !important;
      }
    }

    &.score-red {
      .ngx-slider-selection {
        background: #f08888 !important;
      }
    }

    &.score-blue {
      .ngx-slider-selection {
        background: rgb(141, 174, 227) !important;
      }
    }

    &.score-grey {
      .ngx-slider-selection {
        background: #d5d5d5 !important;
      }
    }
  }
}
