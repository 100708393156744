@use 'sass:list';
@use 'sass:map';
@use '@angular/material' as mat;

$typography-class-prefix: 'custom-font';

/*
$custom-font-levels defines level of fonts per component;
Ultimately it would be good to have a global level per application
*/
$custom-font-levels: (
  login: (
    (40px 48px 400),
    (25px 30px 400),
    (20px 24px 600),
  ),
  dashboard-table: (
    (18px 24 600),
  ),
  app-coaches-table: (
    (20px, 25px, 700),
    (18px 24px 700),
    (14px 20px 400),
    (14px 22px 400),
  ),
  app-patient-pane: (
    (14px 19px 500),
    (14px 20px 500),
    (16px 22px 600),
    (16px 22px 700),
    (18px 24px 600),
    (20px 32px 500),
  ),
  app-program-tab: (
    (18px 32px 500),
  ),
);
$custom-font-login-1: mat.define-typography-level(40px, 48px, 400);
$custom-font-login-2: mat.define-typography-level(25px, 30px);
$custom-font-login-3: mat.define-typography-level(20px, 24px, 600);

@mixin typography($typography-config, $level) {
  #{'.' + $typography-class-prefix + - $level} {
    @include mat.typography-level($typography-config, $level);
  }
}

/*
It returns a new typography config that is a concatenation between the typography config passed in parameter
and the generated typography.
The typography level name is the component name concatenated to the 1-based index of the level.
*/
@function typography-all($typography-config) {
  $custom-font-config: ();

  @each $name, $component-level-config in $custom-font-levels {
    @for $i from 1 through length($component-level-config) {
      $config: list.nth($component-level-config, $i);
      $a: list.nth($config, 1);
      $b: list.nth($config, 2);
      $c: list.nth($config, 3);
      $custom-level: mat.define-typography-level($a, $b, $c);
      $custom-font-config: map.merge(
        $custom-font-config,
        ($name + '-' + $i: $custom-level)
      );
    }
  }

  $typography-config: map.merge($typography-config, $custom-font-config);

  @return $typography-config;
}

/*
for the following level
login: ((40px 48px 400),
        (25px 30px 400),
        (20px 24px 600))
it will generate the classes
custom-font-login-1, custom-font-login-2, custom-font-login-3
and apply mat.typography.level to generate the appropriate font properties
*/
@mixin create-custom-typography-classes($typography-config) {
  // @debug $typography-config;
  @each $level-name, $level in $typography-config {
    @if $level-name != font-family {
      // WTF
      @include typography($typography-config, $level-name);
    }
  }
}
