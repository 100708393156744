/* stylelint-disable */
$remedee-purple: (
  50: #e6e4ec,
  100: #c1bbcf,
  200: #988eaf,
  300: #6e618e,
  400: #3f4974,
  // used
  500: #301d5e,
  600: #7b1fa2,
  // used
  700: #24154c,
  800: #1e1142,
  900: #130a31,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
  ),
);
$grey: (
  50: #f5f6fb,
  100: #ecedf5,
  // used
  200: #babed1,
  300: #9ea3be,
  400: #8a90b0,
  500: #757ca2,
  // used
  600: #6d749a,
  700: #626990,
  800: #585f86,
  900: #305d5e,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    // used
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
  ),
);
$blue: (
  50: #5da9ff,
  500: #3f4974,
  // used
);
