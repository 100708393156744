/* stylelint-disable selector-class-pattern */
@use '@angular/material' as mat;
@use 'sass:map';

@mixin color($theme) {
  // Get the color config from the theme.
  // we use map.get instead of mat.get-color-config
  // because the color was set using map-merge
  //   $color-config: mat.get-color-config($theme);
  $color-config: map.get($theme, 'custom-color');

  // Get the primary color palette from the color-config.
  $primary-palette: map.get(mat.get-color-config($theme), 'primary');
  $secondary-palette: map.get($color-config, 'secondary');

  tr.mat-mdc-header-row {
    background-color: mat.get-color-from-palette($secondary-palette, 500);
  }

  .mdc-data-table__header-cell {
    color: mat.get-contrast-color-from-palette($secondary-palette, 500);
  }

  app-dashboard-table {
    tr.mat-mdc-row {
      background-color: mat.get-contrast-color-from-palette(
        $secondary-palette,
        500
      );
    }
  }

  app-coaches-table {
    .mat-mdc-table {
      background-color: mat.get-color-from-palette($secondary-palette, 100);
    }

    tr.mat-mdc-header-row {
      background-color: unset;
    }

    tr.mat-mdc-row {
      color: mat.get-color-from-palette($primary-palette, 500);
      background-color: unset;

      &.coaches-table__table__row {
        :where(td) {
          color: mat.get-color-from-palette($primary-palette, 500);
        }

        &--selected {
          background-color: mat.get-contrast-color-from-palette(
            $secondary-palette,
            500
          );

          &:hover {
            background-color: mat.get-contrast-color-from-palette(
              $secondary-palette,
              500
            );
          }
        }
      }
    }

    .mdc-data-table__header-cell {
      color: mat.get-color-from-palette($primary-palette, 500);
    }

    .mdc-data-table__cell,
    .mdc-data-table__header-cell {
      border-bottom-style: solid;
      border-bottom-width: 0;
    }
  }
}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: mat.get-typography-config($theme);

  app-coaches-table {
    .mat-mdc-cell {
      @include mat.typography-level($typography-config, app-coaches-table-1);
    }

    .mat-mdc-header-cell {
      @include mat.typography-level($typography-config, app-coaches-table-2);

      opacity: 0.35;
    }
  }

  app-dashboard-table,
  app-admin {
    table {
      font-family: Roboto, sans-serif;

      td {
        font-weight: 400 !important;
      }
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);

  @if $color-config {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);

  @if $typography-config {
    @include typography($theme);
  }
}
