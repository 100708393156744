// the value in this file should be in sync with the value in color-palette.scss

:root {
  --app-primary-50: #e6e4ec;
  --app-primary-100: #c1bbcf;
  --app-primary-200: #988eaf;
  --app-primary-300: #6e618e;
  --app-primary-400: #4f3f76;
  --app-primary-500: #301d5e;
  --app-primary-600: #2b1a56;
  --app-primary-700: #24154c;
  --app-primary-800: #1e1142;
  --app-primary-900: #130a31;

  // primary contrast
  --app-primary-contrast-50: #000;
  --app-primary-contrast-100: #000;
  --app-primary-contrast-200: #000;
  --app-primary-contrast-300: #fff;
  --app-primary-contrast-400: #fff;
  --app-primary-contrast-500: #fff;
  --app-primary-contrast-600: #fff;
  --app-primary-contrast-700: #fff;
  --app-primary-contrast-800: #fff;
  --app-primary-contrast-900: #fff;

  // secondary
  --app-secondary-50: #eeeff4;
  --app-secondary-100: #d6d8e3;
  --app-secondary-200: #babed1;
  --app-secondary-300: #9ea3be;
  --app-secondary-400: #8a90b0;
  --app-secondary-500: #757ca2;
  --app-secondary-600: #6d749a;
  --app-secondary-700: #626990;
  --app-secondary-800: #585f86;
  --app-secondary-900: #454c75;

  // secondary contrast
  --app-secondary-contrast-50: #000;
  --app-secondary-contrast-100: #000;
  --app-secondary-contrast-200: #000;
  --app-secondary-contrast-300: #000;
  --app-secondary-contrast-400: #000;
  --app-secondary-contrast-500: #fff;
  --app-secondary-contrast-600: #fff;
  --app-secondary-contrast-700: #fff;
  --app-secondary-contrast-800: #fff;
  --app-secondary-contrast-900: #fff;
  --app-warning-200: #dd7c7c;
  --app-warning-500: #dc3545;

  // other colors panels
  --app-blue-50: #5da9ff;
  --app-green-500: #198754;
  --app-yellow-500: #ffc107;
  --app-notification-warning: var(--app-yellow-500);

  // others
  --app-white: #fff;
}
