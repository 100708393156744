// global mixin is used to override material hierarchy;
// when they are used, the target selector do not contain "custom-font"
// it rather contains "custom-hierarchy"
@use '@angular/material' as mat;
@use 'sass:map';

@mixin global($theme) {
  $color-config: map.get($theme, 'custom-color');

  $primary-palette: map.get(mat.get-color-config($theme), 'primary');
  $secondary-palette: map.get($color-config, 'secondary');

  app-patient-pane {
    h3.custom-hierarchy-app-patient-pane-1 {
      @extend .custom-font-app-patient-pane-1;
      color: mat.get-color-from-palette($primary-palette, 400);
      opacity: 0.59;
    }
  }
}
