/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-no-vendor-prefix */
/* stylelint-disable selector-class-pattern */
@import 'https://use.fontawesome.com/releases/v5.5.0/css/all.css';
@import 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap';

// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~flag-icon-css/css/flag-icons.min.css';

* {
  box-sizing: border-box;
}

h3 {
  margin: 0 0 20px;
  font-size: 22px;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none;
}

/* Error */
.error {
  margin-top: 5px;
  color: red;
}

.btn-success.disabled,
.btn-success:disabled {
  cursor: not-allowed;
}

/* Form */
label {
  font-weight: 500;
}

.navbar-dark .navbar-brand {
  margin-left: 6px;
}

.mb-3,
.my-3 {
  margin-bottom: 1.4rem !important;
}

body .table thead th {
  background: #f3f5ff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1);
}

.list-group-item {
  padding: 0 1.25rem 15px;
  border: none;
}

/* Responsive */
@media (max-width: 767px) {
  ul.nav.flex-column {
    flex-direction: inherit !important;
  }

  .h2,
  h2 {
    font-size: 1.4rem;
  }

  footer br {
    display: none;
  }

  b,
  strong {
    display: block;
  }
}

@page {
  size: a4;
  margin: 1cm 2cm 0;
}

:root {
  --main-color: inherit;
  --main-button-toggle: #969fb5;
}

.cdk-overlay-backdrop {
  backdrop-filter: blur(12px) !important;
}

@media print {
  .no-print {
    display: none !important;
  }
}

.printable {
  max-width: 17cm;
  margin-right: auto;
  margin-left: auto;
}

.printable .no-print {
  display: none !important;
}

.mat-simple-snackbar {
  white-space: pre-wrap;
}

.mat-dialog-container {
  background-color: white !important;
}

.mat-select-value {
  font-weight: bold;
  color: #301d5e;
}

.mat-tooltip {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: 'Open Sans';

  .secondary-font {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
  }
}

p {
  white-space: pre-line;
}

.loader {
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
  }

  &::before {
    left: -1.5em;
    animation-delay: -0.32s;
  }

  &::after {
    left: 1.5em;
  }

  &,
  &::before,
  &::after {
    width: 1em;
    height: 4em;
    background: #9d45db;
    animation: load1 1s infinite ease-in-out;
  }

  & {
    position: relative;
    transform: translateZ(0);

    margin: 222px auto;

    font-size: 11px;
    color: #9d45db;
    text-indent: -9999em;

    animation-delay: -0.16s;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    height: 4em;
    box-shadow: 0 0;
  }

  40% {
    height: 5em;
    box-shadow: 0 -2em;
  }
}

@media print {
  .chartjs-size-monitor,
  .chartjs-size-monitor-shrink,
  .chartjs-size-monitor-expand {
    position: fixed !important; // cannot stay "absolute"

    > div {
      position: fixed !important;
    }
  }
}

@page {
  margin-bottom: 0.5cm;
  padding-bottom: 5cm;

  .ngx-slider-selection {
    width: 100%;
    margin-left: 0 !important;

    // border-radius: 50px 0 0 50px !important;
    border-radius: 50px !important;
  }
}
