@use '@angular/material' as mat;
@use 'sass:map';

@mixin color($theme) {
  // Get the color config from the theme.
  // we use map.get instead of mat.get-color-config
  // because the color was set using map-merge
  //   $color-config: mat.get-color-config($theme);
  $color-config: map.get($theme, 'custom-color');

  // Get the primary color palette from the color-config.
  $secondary-palette: map.get($color-config, 'secondary');
  $primary-palette: map.get(mat.get-color-config($theme), 'primary');
}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: mat.get-typography-config($theme);
}

@mixin global() {
  @media all and (orientation: landscape) {
    [mode='auto'] {
      .mtx-calendar {
        .mtx-calendar-header-time {
          .mtx-calendar-header-hours,
          .mtx-calendar-header-minutes,
          .mtx-calendar-header-ampm {
            width: 42px !important;
          }
        }
      }
    }
  }
}
/* stylelint-disable scss/at-if-no-null */
@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);

  @include global;

  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);

  @if $typography-config != null {
    @include typography($theme);
  }
}
/* stylelint-enable scss/at-if-no-null */
