@mixin theme($theme) {
  mat-snack-bar-container {
    &.mat-snack-bar-success {
      .mdc-snackbar {
        &__surface {
          background-color: var(--app-primary-500);
        }
      }

      .mdc-button {
        &__label {
          color: var(--app-primary-contrast-500);
        }
      }
    }

    &.mat-snack-bar-info {
      .mdc-snackbar {
        &__surface {
          background-color: var(--app-primary-300);
        }
      }

      .mdc-button {
        &__label {
          color: var(--app-primary-contrast-300);
        }
      }
    }

    &.mat-snack-bar-warning {
      .mdc-snackbar {
        &__surface {
          background-color: var(--app-notification-warning);
        }
      }

      .mdc-button {
        &__label {
          color: var(--app-primary-contrast-300);
        }
      }
    }

    &.mat-snack-bar-error {
      .mdc-snackbar {
        &__surface {
          background-color: var(--app-warning-500);
        }
      }

      .mdc-button {
        &__label {
          color: var(--app-warning-contrast-500);
        }
      }
    }
  }
}
