/* stylelint-disable order/properties-order */
/* stylelint-disable declaration-block-no-redundant-longhand-properties */
/* stylelint-disable selector-class-pattern */
@use '@angular/material' as mat;
@use '@material/tab-indicator';
@use 'sass:map';

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator {
  @include tab-indicator.underline-color(var(--main-color));
}

@mixin color($theme) {
  // Get the color config from the theme.
  // we use map.get instead of mat.get-color-config
  // because the color was set using map-merge
  //   $color-config: mat.get-color-config($theme);
  $color-config: map.get($theme, 'custom-color');

  // Get the primary color palette from the color-config.
  $secondary-palette: map.get($color-config, 'secondary');
  $primary-palette: map.get(mat.get-color-config($theme), 'primary');

  app-admin {
    .mat-mdc-tab:not(.mat-mdc-tab-disabled) {
      color: mat.get-color-from-palette($primary-palette, 500);

      /* .mdc-tab__text-label {
        opacity: 0.6;
      } */

      &.mdc-tab--active {
        background: mat.get-color-from-palette($primary-palette, 400);

        .mdc-tab__text-label {
          opacity: initial;
          color: mat.get-contrast-color-from-palette($primary-palette, 500);
        }
      }
    }

    .mat-tab-group.mat-primary .mat-ink-bar,
    .mat-tab-nav-bar.mat-primary .mat-ink-bar {
      background-color: #7b1fa2;
    }

    .mat-mdc-tab-label-container {
      transform: translateX(0);
      border-right-color: mat.get-color-from-palette($primary-palette, 600);
      border-right-width: 4px;
      border-right-style: solid;
    }
  }
}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: mat.get-typography-config($theme);

  app-dashboard {
    .mdc-tab__text-label {
      @include mat.typography-level($typography-config, dashboard-table-1);
    }
  }

  app-patient-pane {
    .mdc-tab__text-label {
      @include mat.typography-level($typography-config, app-patient-pane-5);
    }
  }

  app-admin {
    .mdc-tab {
      &.mdc-tab--active {
        @include mat.typography-level($typography-config, app-coaches-table-1);
      }
    }
  }
}

@mixin global() {
  .mat-mdc-tab.mdc-tab {
    padding-right: 8px;
    padding-left: 8px;
    flex-grow: 0 !important;
    min-width: initial;
  }

  .mat-mdc-tab-labels {
    gap: 8px;
  }

  app-patient-pane {
    .mat-mdc-tab-labels {
      gap: 0;
    }
  }

  app-admin {
    mat-tab-header {
      padding-top: 5%;
    }

    .mdc-tab {
      font-size: 14px;
      font-weight: 500;
      margin: 0 10px;
      width: 160px;
    }

    .mdc-tab--active {
      border-radius: 9px;
      text-align: left;
      margin: 0 10px;
      opacity: 1 !important;
    }

    .mdc-tab-indicator__content--underline {
      width: 0;
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $typography-config: mat.get-typography-config($theme);

  @include global;

  @if $color-config {
    @include color($theme);
  }

  @if $typography-config {
    @include typography($theme);
  }
}
