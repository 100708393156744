/* stylelint-disable selector-class-pattern */
@use '@angular/material' as mat;
@use '@material/textfield';
@use 'sass:map';

@mixin color($theme) {
  // Get the color config from the theme.
  // we use map.get instead of mat.get-color-config
  // because the color was set using map-merge
  //   $color-config: mat.get-color-config($theme);
  $color-config: map.get($theme, 'custom-color');

  // Get the primary color palette from the color-config.
  $secondary-palette: map.get($color-config, 'secondary');
  $primary-palette: map.get(mat.get-color-config($theme), 'primary');
}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: mat.get-typography-config($theme);
}

@mixin global() {
  survey-container {
    .mat-radio-button {
      .mat-radio-inner-circle {
        background-color: #9d45db; // !important;
      }

      .mat-radio-outer-circle {
        border-color: #9d45db; // !important;
      }

      &.mat-radio-disabled {
        .mat-radio-inner-circle {
          background-color: rgb(
            170 170 170
          ) !important; // TODO: color used is the same as the user agent; should be part of the theme
        }

        .mat-radio-outer-circle {
          border-color: rgb(
            170 170 170
          ) !important; // TODO: color used is the same as the user agent; should be part of the theme
        }

        .mat-radio-label-content {
          color: inherit;
        }
      }
    }
  }
}

/* stylelint-disable scss/at-if-no-null */
@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $typography-config: mat.get-typography-config($theme);

  @include global;

  @if $color-config != null {
    @include color($theme);
  }

  @if $typography-config != null {
    @include typography($theme);
  }
}
